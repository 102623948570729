import React, { lazy, Suspense } from 'react';

// Lazy load the Player component
const Player = lazy(() => import('@lottiefiles/react-lottie-player').then(module => ({ default: module.Player })));

const Lottie = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Player {...props} />
    </Suspense>
  );
};

export default Lottie;
