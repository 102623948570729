import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
// import { Player } from '@lottiefiles/react-lottie-player';
import Lottie from './Lottie';

const Image = ({
  file,
  image,
  alt,
  imgStyle,
  ...other
}) => {
  if (file?.contentType === 'image/svg+xml') {
    return (
      <img
        src={file?.url}
        alt={alt != undefined ? alt : ''}
        width={file.details.image.width}
        height={file.details.image.height}
        aria-hidden={alt == undefined}
        style={imgStyle}
        {...other}
      />
    )
  }
  if (file?.contentType === 'application/json') {
    return (
      <Lottie
        autoplay
        loop
        src={file?.url}
      />
    )
  }
  return (
    <>
      {image && 
        <GatsbyImage image={image} alt={alt != undefined ? alt : ''} aria-hidden={alt == undefined} imgStyle={imgStyle} {...other} />
      }
    </>
  )
}

export default Image
